

















































import type { PropType } from 'vue';
import { defineComponent, watch, ref, nextTick } from '@vue/composition-api';
import type { PhotorankApiMediaItem } from '@vf/api-client/src/api-types';
import { SocialNativeLayout, SocialNativeTranslations } from '@vf/api-contract';

export default defineComponent({
  name: 'VfSocialNativeGallery',
  props: {
    /** Layout */
    layout: {
      type: String as PropType<SocialNativeLayout>,
      default: SocialNativeLayout.HOMEPAGE,
    },
    // array result placementLayout and media on correct column
    placementLayoutAndMedia: {
      type: Array as PropType<Array<PhotorankApiMediaItem[]>>,
      default: () => [],
    },
    // define how many media go inside columns
    placementLayout: {
      type: Array as PropType<number[]>,
      default: () => [],
    },
    translations: {
      type: Object as PropType<SocialNativeTranslations>,
      required: true,
    },
    // when use swiper placementLayout should be [1]
    useSwiper: Boolean,
  },
  setup(props) {
    let unwatch = () => undefined;
    const isPaginated = ref(true);

    const onSwiperInstance = (swiper) => {
      if (swiper.scroller) {
        const scrollerInstance = swiper.scroller;

        unwatch();
        unwatch = watch(
          () => props.placementLayoutAndMedia.length,
          async () => {
            isPaginated.value = true;
            await nextTick();
            scrollerInstance.refresh();
            await nextTick();
            isPaginated.value = scrollerInstance.pages > 1;
          },
          { immediate: true }
        );
      }
    };

    return {
      isPaginated,
      onSwiperInstance,
    };
  },
});
