




































































































import { defineComponent, Ref, ref, onMounted } from '@nuxtjs/composition-api';
import {
  ProductReviewSortingParameter,
  ProductReviewVoteType,
} from '@vf/api-contract';
import { useReviews } from '@vf/composables';
import VfReviews from '@vf/ui/components/Organism.Reviews.vue';
import { scrollTo as scrollToTop } from '@vf/shared/src/utils/helpers';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import useRootInstance from '@/shared/useRootInstance';

const REDUCED_LIST_COUNT = 5;

export default defineComponent({
  name: 'Reviews',
  components: {
    VfReviews,
  },
  layout: 'emptyNative',
  transition: 'fade',
  setup() {
    const { root } = useRootInstance();
    const { isProductReviewsPageEnabled } = useFeatureFlagsStore();

    const {
      setProductId,
      getProductReviews,
      getProductReviewsConf,
      rollup,
      translations,
      properties,
      sorting,
      paging,
      filters,
      setSorting,
      changePage,
      addFilter,
      removeFilter,
      productReview,
      flagReview,
      addReviewVote,
    } = useReviews(root, 'product');

    const isScrollToReviewsWhenPaging =
      root.$themeConfig.reviews.isScrollToReviewsWhenPaging;

    const isLoading: Ref<boolean> = ref(true);
    const reviewsCanLoad: Ref<boolean> = ref(true);

    const productId: string = root.$route.params.id.toUpperCase();

    if (!isProductReviewsPageEnabled) {
      reviewsCanLoad.value = false;
      root.$log.warn(
        `[@theme/pages/reviews/_id.vue::setup] Page cannot load. isProductReviewsPageEnabled: <${isProductReviewsPageEnabled}>, id: <${productId}>`
      );
    }

    const handleFlagReviewSubmit = (eventObj) => {
      flagReview(
        eventObj.reviewId,
        eventObj.comments,
        eventObj.flagType,
        eventObj.email,
        eventObj.mediaId
      );
    };

    const getReviews = async (triggerScrollToTop = false) => {
      setProductId(productId);
      await getProductReviews();
      triggerScrollToTop && scrollToTop();
    };

    const reloadPage = () => {
      root.$router.go(0);
    };

    onMounted(async () => {
      if (!reviewsCanLoad.value) {
        return;
      }

      await getProductReviewsConf();
      setSorting(
        (properties.value
          ?.DEFAULT_REVIEW_SORT_ORDER as ProductReviewSortingParameter) ||
          ProductReviewSortingParameter.MostHelpful
      );

      await getReviews(true);

      if (!productReview.value) {
        reviewsCanLoad.value = false;
        root.$log.warn(
          `[@theme/pages/reviews/_id.vue::onMounted] Product with id <${productId}> does not exist in Power Reviews.`
        );
      } else {
        if (!productReview.value.reviewsCount) {
          root.$log.warn(
            `[@theme/pages/reviews/_id.vue::onMounted] Product with id <${productId}> has no existing reviews.`
          );
        }
      }

      isLoading.value = false;
    });

    return {
      isLoading,
      getReviews,
      rollup,
      translations,
      sorting,
      paging,
      filters,
      addFilter,
      removeFilter,
      handleFlagReviewSubmit,
      setSorting,
      changePage,
      productReview,
      addReviewVote,
      ProductReviewVoteType,
      reducedListCount: REDUCED_LIST_COUNT,
      isScrollToReviewsWhenPaging,
      productId,
      reviewsCanLoad,
      reloadPage,
    };
  },
});
