export const productAddToCart = {
  largeScreenClass: 'vf-button--medium vf-button--fullWidth',
  scrollToErrorOffset: 20,
  scrollToErrorOffsetWithTopStickyHeader: 90,
  activeClass: 'vf-button--primary',
  notifyClass: 'vf-button--primary',
  pdpUrlWithAttributes: false,
  modal: {
    loggedIn: 'loyaltyEnrollment',
    notLoggedIn: 'loyaltyEnrollment',
  },
  quickshopScrollToTopAfterAddToCart: true,
};
