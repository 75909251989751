














































































import type { PropType } from 'vue';
import { defineComponent } from '@vue/composition-api';
import type { SocialNativeUploadMediaTranslations } from '@vf/api-contract';
import { required, email } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';

export default defineComponent({
  name: 'VfSocialNativeSignInForm',
  mixins: [validationMixin],
  props: {
    translations: {
      type: Object as PropType<SocialNativeUploadMediaTranslations>,
      required: true,
    },
    createUserError: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['sign-in'],
  data() {
    return {
      name: null,
      email: null,
      isTermsConfirmed: false,
    };
  },
  methods: {
    async signIn() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.$emit('sign-in', {
        screen_name: this.name,
        email: this.email,
      });
    },
  },
  validations: {
    email: {
      required,
      email: (eml) => email(eml ? eml.toLowerCase() : eml), // vuelidate considers domain names with captial letters as invalid
    },
    name: {
      required,
    },
    isTermsConfirmed: {
      required,
      sameAs: (val) => val === true,
    },
  },
});
