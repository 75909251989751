export const header = {
  logoWidth: {
    small: 76, // 4.75rem
    medium: 124, // 7.75rem
    large: 124, // 7.75rem
  },
  logoHeight: {
    small: 31, // '1.9375rem'
    medium: 50, // '3.125rem'
    large: 50, // '3.125rem'
  },
  utilityIcon: 14,
  shopAllIcon: 'chevron_right',
  navItemSettingsExist: true,
  showEmptySearchSuggestions: false,
  accordionIconType: 'plus',
  accordionIconTypeRedesign: 'arrow',
  accordionIconSize: '16px',
  showTopDivider: true,
  minUtilityNavHeight: 50,
  closeIconName: 'cross',
  enableVisualSearch: true,
  showClearButtonInMobileSearch: true,
  showClearButtonInDesktopSearch: true,
  searchAllAsButton: false,
  isMegaMenuIconHidden: true,
  isMegaMenuSeparatorHidden: true,
  isMegaMenuLinkUnderlined: true,
  isMobileMenuWithAccordion: true,
  isStickinessEnabled: true,
  hideUtilityNavWhenSticky: true,
  alwaysShowIcon: true,
  isMobileTopBarSticky: true,
  minicartIconName: 'cart',
  disableBodyScrollInMenu: true,
  onlyAllowOneOpenL1Item: true,
  showOverlayBehindMegaMenu: true,
};
